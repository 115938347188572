import {formatGeneral} from "cleave-zen";

window.ghs = Object();
window.ghs.payLoads = undefined;

window.ghs.stopPayLoad = function () {
  clearInterval(window.ghs.payLoads);
  window.ghs.payLoads = undefined;
};

window.ghs.select_subscription_clear = function () {
  $(".select_subscription").html(
    '<h3 class="m-0 pt-0">&nbsp;</h3><div class="border px-3 p-4">Load...</div>'
  );
};
window.ghs.ghs_load_payment_info = function (link_path) {
  $.ajax({
    type: "GET",
    cache: false,
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        $('meta[name="csrf-token"]').attr("content")
      );
    },
    url: link_path,
    success: function (html) {
      $("#pay_info").html(html);
    },
    error: function (error) {
      console.log("error", error);
    },
  });
};

window.ghs.initAutocomplete = function(){
  let input = document.querySelector('.city_state_autocomplete');
  const options = {
    componentRestrictions: {country: ['us']},
    fields: ['locality'],
    types: ['locality']
  }
  console.log(input);
  let autocomplete = new google.maps.places.Autocomplete(input, options);

  input.focus();
  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();
    input.value = place.name;
  });
}

window.ghs.formatPhone = function (value){
  return formatGeneral(value, {
    blocks: [0,3,3,4],
    delimiters: ['(', ') ', '-'],
    delimiterLazyShow: true,
    numericOnly: true
  });
}

$(document).ready(function(){
  $.each($('input[type="tel"]'), (index, element) => {
    element.value = window.ghs.formatPhone(element.value);
    $(element).on('input', () => {
      element.value = window.ghs.formatPhone(element.value);
    });
  });
});


$(document).on("click", ".stripe-checkout-button", function (event) {
  let this_button = $(this);
  this_button.attr("disabled", "disabled");
  fetch(this_button.data("link"), { method: "POST" })
    .then(function (response) {
      return response.json();
    })
    .then(function (session) {
      this_button.removeAttr("disabled");
      return stripe.redirectToCheckout({ sessionId: session.id });
    })
    .then(function (result) {
      if (result.error) {
        this_button.removeAttr("disabled");
        alert(result.error.message);
      }
    })
    .catch(function (error) {
      this_button.removeAttr("disabled");
      console.error("Error:", error);
    });
});

$(document).on("input", "#billing_form_coupon", function (event) {
  const coupon = $(this).val();
  const price_id = $("#billing_form_price_id").val();
  const link_path = $(this).data("url");
  window.ghs.find_coupon(coupon, price_id, link_path);
});

window.ghs.find_coupon = function (coupon, price_id, link_path) {
  $.ajax({
    type: "GET",
    cache: false,
    data: { id_coupon: coupon, price_id: price_id },
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        $('meta[name="csrf-token"]').attr("content")
      );
    },
    url: link_path,
    success: function (html) {
      $(".coupon_result").html(html);
    },
    error: function (error) {
      console.log("error", error);
    },
  });
};

$(document).on("change", "#billing_form_price_id", function (event) {
  const price_id = $(this).val();
  const price = window.ghs.all_prices_title.get(price_id);

  if (price_id === "") {
    $(".price-select").html("");
  } else {
    $(".price-select").html(`<span id="price_1">${price}</span>`);
  }
});

$("#new_billing_form").serializeArray();

$(document).on("submit", "#new_billing_form", function (event) {
  $(".errors-pay").html("");
  let valuesToSubmit = $(this).serializeArray();
  const path_url = $(this).attr("action");
  let is_valid = true;

  $("input").each(function (index, value2) {
    let is_required = $(this).hasClass("required");
    let value = $(this).val();

    if (is_required && value === "") {
      $(this).focus();
      is_valid = false;
      window.ghs.show_pay_error({
        error: { message: "Fill in all required fields of the form" },
      });
      return false;
    }
  });
  if (!is_valid) {
    return false;
  }
  $("select").each(function (index, value2) {
    let is_required = $(this).hasClass("required");
    let value = $(this).val();
    console.log(
      " attb=",
      is_required,
      " value=",
      value === "",
      $(this).attr("name"),
      $(this).attr("required")
    );

    if (is_required && value === "") {
      $(this).focus();
      is_valid = false;
      window.ghs.show_pay_error({
        error: { message: "Fill in all required fields of the form" },
      });
      return false;
    }
  });

  if (is_valid) {
    console.log("submit #new_billing_form validate");

    window.ghs.disable_form("making a payment.");
    const price_id = $("#billing_form_price_variant").val();
    alert(price_id);
    stripe
      .createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          name: `${$("#billing_form_first_name").val()} ${$(
            "#billing_form_last_name"
          ).val()}`,
          address: {
            city: $("#billing_form_address_city").val(),
            country: $("#billing_form_address_country").val(),
            line1: $("#billing_form_address_street").val(),
            line2: "",
            postal_code: $("#billing_form_address_zipcode").val(),
            state: $("#billing_form_address_state").val(),
          },
          email: $("#billing_form_email").val(),
          phone: $("#billing_form_phone").val(),
        },
      })
      .then((result) => {
        if (result.error) {
          window.ghs.show_pay_error(result.error);
          window.ghs.enable_form();
        } else {
          valuesToSubmit.push({
            name: "paymentId",
            value: result["paymentMethod"]["id"],
          });
          window.ghs.disable_form("making a payment..");
          window.ghs.createSubscription({
            path_url: path_url,
            valuesToSubmit: valuesToSubmit,
            paymentMethodId: result["paymentMethod"]["id"],
            priceId: price_id,
          });
        }
      });
  }

  return false; // prevents normal behaviour
});

window.ghs.createSubscription = function ({
  path_url,
  valuesToSubmit,
  paymentMethodId,
  priceId,
}) {
  return (
    $.ajax({
      type: "POST",
      url: path_url,
      data: valuesToSubmit,
      dataType: "JSON",
    })
      .then((response) => {
        return response;
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        window.ghs.disable_form("making a payment...");
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          console.log("result.error.444");
          throw result;
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then((result) => {
        window.ghs.disable_form("making a payment....");
        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      .then(window.ghs.handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      .then(window.ghs.handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then(window.ghs.onSubscriptionComplete)
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        window.ghs.enable_form();
        window.ghs.show_pay_error(error);
      })
  );
};

window.ghs.handlePaymentThatRequiresCustomerAction = function ({
  subscription,
  invoice,
  priceId,
  paymentMethodId,
  isRetry,
}) {
  window.ghs.disable_form("making a payment .");
  if (subscription && subscription.status === "active") {
    window.ghs.disable_form("making a payment ..");
    // Subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  }

  // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
  // If it's a retry, the payment intent will be on the invoice itself.
  let paymentIntent = invoice
    ? invoice.payment_intent
    : subscription.latest_invoice.payment_intent;
  window.ghs.disable_form("making a payment ...");
  if (paymentIntent === null || paymentIntent === undefined) {
    return { subscription, priceId, paymentMethodId };
  }

  if (
    paymentIntent.status === "requires_action" ||
    (isRetry === true && paymentIntent.status === "requires_payment_method")
  ) {
    window.ghs.disable_form("making a payment ...");
    return stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId,
      })
      .then((result) => {
        window.ghs.disable_form("making a payment ...");
        if (result.error) {
          window.ghs.enable_form();
          console.log("result.error.111");
          throw result;
        } else {
          window.ghs.disable_form("making a payment ....");
          if (result.paymentIntent.status === "succeeded") {
            window.ghs.disable_form("making a payment ....");
            // Show a success message to your customer.
            return {
              priceId: priceId,
              subscription: subscription,
              invoice: invoice,
              paymentMethodId: paymentMethodId,
            };
          }
        }
      })
      .catch((error) => {
        console.log("result.error.222");
        throw error;
      });
  } else {
    window.ghs.disable_form("making a payment.....");
    // No customer action needed.
    return { subscription, priceId, paymentMethodId };
  }
};

window.ghs.handleRequiresPaymentMethod = function ({
  subscription,
  paymentMethodId,
  priceId,
}) {
  window.ghs.disable_form("making a payment. .");
  if (subscription && subscription.status === "active") {
    // subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  } else if (
    subscription.latest_invoice.payment_intent !== null &&
    subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
  ) {
    // Using localStorage to manage the state of the retry here,
    // feel free to replace with what you prefer.
    // Store the latest invoice ID and status.
    localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
    localStorage.setItem(
      "latestInvoicePaymentIntentStatus",
      subscription.latest_invoice.payment_intent.status
    );
    throw { error: { message: "Your card was declined." } };
  } else {
    return { subscription, priceId, paymentMethodId };
  }
};

window.ghs.onSubscriptionComplete = function (result) {
  window.ghs.disable_form("making a payment......");
  console.log("onSubscriptionComplete", result);
  // Payment was successful.
  if (
    result &&
    result.subscription &&
    (result.subscription.status === "active" ||
      result.subscription.status === "trialing" ||
      result.subscription.status === "incomplete")
  ) {
    window.ghs.disable_form("payment confirmation...");
    window.ghs.disable_form("create subscription...");
    setTimeout(function () {
      window.location.href = $(".confirm_link").data("link");
    }, 1000);
  }
};

window.ghs.disable_form = function (status_text = "Loading...") {
  const spinner =
    '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
  $("#submit-button").html(`${spinner} ${status_text}`);
  $(".fieldset-payment-form").prop("disabled", true);
};

window.ghs.enable_form = function () {
  $("#submit-button").html($("#submit-button").data("title"));
  $(".fieldset-payment-form").prop("disabled", false);
};

window.ghs.show_pay_error = function (error) {
  const msg =
    (error && error.error && error.error.message) ||
    (error && error.message) ||
    "Payment error";
  $(".errors-pay").html(`<div class="alert alert-danger">${msg}</div>`);
};

window.ghs.displayError = function (el_id, event) {
  if (event.error) {
    $(el_id).html(event.error.message);
  } else {
    $(el_id).html("");
  }
};

window.ghs.coachRegistrationForm = function () {
  const coach_type_id = $(".coach-type-field select").val();
  const coach_type_selected =
    coach_type_id !== undefined && coach_type_id.length > 0;
  if (coach_type_selected) {
    $(".college-field").removeClass("d-none");
  } else {
    $(".college-field").addClass("d-none");
  }

  const college_id = $(".college-field select").val();
  const college_is_select = college_id !== undefined && college_id.length > 0;
  if (coach_type_selected && college_is_select) {
    $(".sport-field").removeClass("d-none");
  } else {
    $(".sport-field").addClass("d-none");
  }

  const sport_id = $(".sport-field select").val();
  const sport_is_select = sport_id !== undefined && sport_id.length > 0;
  if (coach_type_selected && college_is_select && sport_is_select) {
    if (coach_type_id === "head_coach") {
      $(".share-access-field").removeClass("d-none");
    } else {
      $(".coach-share-access-field").removeClass("d-none");
      $(".share-access-field").addClass("d-none");
      $(".submit-access-field").removeClass("d-none");
    }
  } else {
    $(".coach-share-access-field").addClass("d-none");
    $(".share-access-field").addClass("d-none");
    $(".submit-access-field").addClass("d-none");
    $(".submit-field").removeClass("d-none");
  }

  if (coach_type_id === "head_coach") {
    const share_access = $(".share-access-field select").val();
    const share_access_is_select =
      share_access !== undefined && share_access.length > 0;
    if (
      coach_type_selected &&
      college_is_select &&
      sport_is_select &&
      share_access_is_select
    ) {
      if (share_access === "1") {
        $(".coach-share-access-field").removeClass("d-none");
      } else {
        $(".coach-share-access-field").addClass("d-none");
      }
      $(".submit-field").removeClass("d-none");
    } else {
      $(".submit-field").addClass("d-none");
      $(".coach-share-access-field").addClass("d-none");
    }
    $(".submit-access-field").addClass("d-none");
    $(".type_coach_select").removeClass("d-none");
  } else {
    if (coach_type_selected && college_is_select && sport_is_select) {
      $(".coach-share-access-field").removeClass("d-none");
      $(".type_coach_select").addClass("d-none");
    } else {
      $(".coach-share-access-field").addClass("d-none");
      $(".type_coach_select").removeClass("d-none");
    }
    $(".submit-field").addClass("d-none");
  }
};

$(document).on("ready turbo:load", function () {
  window.ghs.coachRegistrationForm();
});
$(document).on("change", "#coach_type_id", function (event) {
  window.ghs.coachRegistrationForm();
});
$(document).on("change", "#college_id", function (event) {
  window.ghs.coachRegistrationForm();
});
$(document).on("change", "#sport_coach_register_id", function (event) {
  window.ghs.coachRegistrationForm();
});
$(document).on("change", "#share_access", function (event) {
  window.ghs.coachRegistrationForm();
});

$(document).on("click", ".other_playing_experience_create", function (event) {
  event.preventDefault();

  let th = $(this);
  let data = {};
  $(".other_playing_experience_form input").each(function (i, item_html) {
    data[$(item_html).attr("name")] = $(item_html).val();
  });
  $(".other_playing_experience_form select").each(function (i, item_html) {
    data[$(item_html).attr("name")] = $(item_html).val();
  });

  $.ajax({
    type: "POST",
    cache: false,
    data: data,
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        $('meta[name="csrf-token"]').attr("content")
      );
    },
    url: th.data("link"),
    success: function (html) {
      $(".refresh-ajax-ope").html($(html).children(".form_ajax").html());
    },
    error: function (error) {
      alert("error");
      console.log("error", error);
    },
  });

  return false;
});

$(document).on("click", ".recommendation_create_button", function (event) {
  event.preventDefault();

  let th = $(this);
  let data = {};
  $(".recommendation_create_form input").each(function (i, item_html) {
    data[$(item_html).attr("name")] = $(item_html).val();
  });

  $.ajax({
    type: "POST",
    cache: false,
    data: data,
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        $('meta[name="csrf-token"]').attr("content")
      );
    },
    url: th.data("link"),
    success: function (html) {
      $(".refresh-ajax-recommendation").html(
        $(html).children(".form_ajax_recommendation").html()
      );
    },
    error: function (error) {
      alert("error");
      console.log("error", error);
    },
  });

  return false;
});

$(document).on("click", ".honor_create_button", function (event) {
  event.preventDefault();

  let th = $(this);
  let data = {};
  $(".honor_create_form input").each(function (i, item_html) {
    data[$(item_html).attr("name")] = $(item_html).val();
  });

  $.ajax({
    type: "POST",
    cache: false,
    data: data,
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        $('meta[name="csrf-token"]').attr("content")
      );
    },
    url: th.data("link"),
    success: function (html) {
      $(".refresh-ajax-honor").html(
        $(html).children(".form_ajax_honor").html()
      );
    },
    error: function (error) {
      alert("error");
      console.log("error", error);
    },
  });

  return false;
});

$(document).on("click", ".extracurricular_create_button", function (event) {
  event.preventDefault();

  let th = $(this);
  let data = {};
  $(".extracurricular_create_form input").each(function (i, item_html) {
    data[$(item_html).attr("name")] = $(item_html).val();
  });

  $.ajax({
    type: "POST",
    cache: false,
    data: data,
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        $('meta[name="csrf-token"]').attr("content")
      );
    },
    url: th.data("link"),
    success: function (html) {
      $(".refresh-ajax-extracurricular").html(
        $(html).children(".form_ajax_extracurricular").html()
      );
    },
    error: function (error) {
      alert("error");
      console.log("error", error);
    },
  });

  return false;
});

$(document).on("click", ".watch_me_create_button", function (event) {
  event.preventDefault();

  let th = $(this);
  let data = {};
  $(".watch_me_create_form input").each(function (i, item_html) {
    data[$(item_html).attr("name")] = $(item_html).val();
  });
  $(".watch_me_create_form select").each(function (i, item_html) {
    data[$(item_html).attr("name")] = $(item_html).val();
  });

  $.ajax({
    type: "POST",
    cache: false,
    data: data,
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        $('meta[name="csrf-token"]').attr("content")
      );
    },
    url: th.data("link"),
    success: function (html) {
      $(".refresh-ajax-watch_me").html(
        $(html).children(".form_ajax_watch_me").html()
      );
    },
    error: function (error) {
      alert("error");
      console.log("error", error);
    },
  });

  return false;
});
